import { ECurrencyCode } from 'types';

export const HISTORY_LENGTH_ON_SITE_LOAD = 2;
export const APP_CONTENT = 'App__container';
export const MAX_INT = 2147483647;
export const PROPOSAL_OTHER_REJECT_REASON_ID = 7;

/**/
export const TABLE_SCROLL_APPEARANCE = {
  LARGE: { x: 980, y: 420 },
  MIDDLE: { x: 780, y: 420 }
};

/**/
export const BODY_APP_CLASS = 'Body--app';
export const BODY_DESKTOP_CLASS = 'Body--desktop';
export const BODY_FULLSCREEN_CLASS = 'Body--fullscreen';
export const BODY_IOS_CLASS = 'Body--ios';
export const BODY_IPAD_CLASS = 'Body--ipad';
export const BODY_MOBILE_CLASS = 'Body--mobile';
export const BODY_TOUCH_CLASS = 'Body--touch';
export const BODY_DARK_CLASS = 'Body--dark';
export const BODY_LIGHT_CLASS = 'Body--light';

/**/
export const BREAKPOINTS = {
  LG: 992,
  MD: 768,
  SM: 576,
  XL: 1200,
  XLG: 1024,
  XXL: 1540
};

/**/
export const CURRENCY_SYMBOLS: {
  [key in keyof typeof ECurrencyCode]: string;
} = {
  [ECurrencyCode.EUR]: '€',
  [ECurrencyCode.USD]: '$',
  [ECurrencyCode.DKK]: 'DKK',
  [ECurrencyCode.NOK]: 'NOK',
  [ECurrencyCode.SEK]: 'SEK',
  [ECurrencyCode.GBP]: '£'
};

/**
 *
 */
export const ROUTES = {
  ACTIVITY_PROFILE: '/activity-profile',
  ANALYTICS: '/analytics',
  AREA_EDITOR: '/area/editor',
  BIOLYTICS: '/biolytics',
  CAMPAIGN_EDITOR: '/campaign/create',
  CAMPAIGN_SELECT: '/campaign/select',
  COMPANIES: '/companies',
  COMPANY: '/company',
  COMPARE: '/compare',
  CONTACT: '/contact',
  CRM: '/crm',
  CRM_COMPANY: '/crm/company',
  CRM_FARM: '/crm/farm',
  CRM_VESSEL: '/crm/vessel',
  CUSTOM_REPORT: '/custom-report',
  DISCLAIMER: '/disclaimer',
  EVENTS: '/events',
  EVENT_EDITOR: '/events/editor',
  FARM: '/farm',
  FARMS: '/farms',
  HARVESTING: '/harvesting',
  HISTORICAL_TRACK: '/historical-track',
  HOME: '/',
  LOGIN: '/login',
  MAP: '/map',
  MY_VESSELS: '/vessels/myvessels',
  NEWS: '/news',
  NEWS_EDITOR: '/news/editor',
  NOTIFICATIONS: '/notifications',
  PAGE404: '/404',
  PERSON: '/person',
  PLAYER: '/player',
  PRIVACY_POLICY: '/privacy-policy',
  SERVICES: '/services',
  SERVICE_EDITOR: '/service/editor',
  SERVICE_PROVIDER: '/service/provider',
  TEST: '/test',
  TEST_BUTTONS: '/test/buttons',
  USER: '/user',
  VESSEL: '/vessel',
  VESSELS: '/vessels'
};

export const FF_PRODUCT_ROUTES = {
  ACTIVITY_PROFILE: '/service/provider/26/products/2025',
  ANALYTICS: '/service/provider/26/products/451',
  CRM: '/service/provider/26/products/489',
  HISTORICAL_TRACK: '/service/provider/26/products/1756',
  MAP: '/service/provider/26/products/450',
  NEWS: '/service/provider/26/products/459',
  SERVICES: '/service/provider/26/products/459',
  VESSELS: '/service/provider/26/products/450'
};

export const AF_PRODUCT_ROUTES = {
  ANALYTICS: '/service/provider/243/products/808',
  BIOLYTICS: '/service/provider/243/products/1311',
  COMPANIES: '/service/provider/243/products/807',
  CRM: '/service/provider/243/products/810',
  FACILITIES: '/service/provider/243/products/807',
  HARVESTING: '/service/provider/243/products/1783',
  HISTORICAL_TRACK: '/service/provider/243/products/2005',
  MAP: '/service/provider/243/products/807',
  NEWS: '/service/provider/243/products/809',
  SERVICES: '/service/provider/243/products/809',
  VESSELS: '/service/provider/243/products/807'
};

export const PLACEHOLDER = {
  ACCESS: '/assets/placeholders/access_placeholder.svg',
  BANNER: '/assets/placeholders/banner_placeholder.svg',
  BROOD: '/assets/placeholders/brood_placeholder.svg',
  COMPANY: '/assets/placeholders/company_placeholder.svg',
  CONTACT: '/assets/placeholders/contact_placeholder.svg',
  CONTACT_CARD: '/assets/placeholders/contact_card_placeholder.svg',
  EMPTY_COMPANIES: '/assets/placeholders/empty_companies.svg',
  EMPTY_EVENTS: '/assets/placeholders/empty_events.svg',
  EMPTY_FARMS: '/assets/placeholders/empty_farms.svg',
  EMPTY_SEARCH: '/assets/placeholders/empty_search.svg',
  EMPTY_SERVICE_PROVIDERS: '/assets/placeholders/empty_service_providers.svg',
  EMPTY_VESSELS: '/assets/placeholders/empty_vessels.svg',
  EVENT: '/assets/placeholders/event_placeholder.svg',
  FACTORY: '/assets/placeholders/factory_placeholder.svg',
  FISHFARM: '/assets/placeholders/fishfarm_placeholder.svg',
  FLEETS: '/assets/placeholders/fleets_placeholder.svg',
  GROUP: '/assets/placeholders/group_placeholder.svg',
  LOCATION_CARD: '/assets/placeholders/location_card_placeholder.svg',
  NOT_FOUND_AF: '/assets/placeholders/page404AF.svg',
  NOT_FOUND_FF: '/assets/placeholders/page404FF.svg',
  PRODUCT: '/assets/placeholders/product_placeholder.svg',
  SIZE_WARNING: '/assets/placeholders/size_warning.svg',
  SMOLT: '/assets/placeholders/smolt_placeholder.svg',
  TABLE: '/assets/placeholders/table_placeholder.svg',
  VESSEL: '/assets/placeholders/vessel_placeholder.svg',
  VESSEL_CARD: '/assets/placeholders/vessel_card_placeholder.svg',
  VIDEO: '/assets/placeholders/video_placeholder.svg',
  _PATH: '/assets/placeholders/',
  _SUFFIX: '_card_placeholder.svg'
};

export const PROMO_SEARCH_VALUE = 'promotion';

/**
 * Chunk load error messages for different browsers.
 */
export const CHUNK_LOAD_ERROR_FRAGMENTS = [
  'ChunkLoadError',
  'Loading chunk',
  'Loading CSS chunk',
  "SyntaxError: Unexpected token '<'",
  "SyntaxError: expected expression, got '<'",
  "Uncaught SyntaxError: Unexpected token '<'"
];
