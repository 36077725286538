import React, { ReactElement } from 'react';
import dayjs from 'dayjs';

import { ROUTES } from 'other';
import { ENotification } from 'types';

/**/
export function mapTypeToPath(body: string, type: ENotification): string {
  switch (type) {
    case ENotification.PROMOTION:
      return ROUTES.NEWS + '/' + JSON.parse(body).articleId;

    case ENotification.ADMIN:
    default:
      return ROUTES.NEWS;
  }
}

/**
 *
 */
export function mapTypeToIcon(type: ENotification): string {
  switch (type) {
    case ENotification.PROMOTION:
      return 'bell';

    case ENotification.ADMIN:
    default:
      return 'speed';
  }
}

/**
 *
 * @param date
 */
export function getNotificationDate(date: string): string {
  if (!date || typeof date !== 'string') return '';

  const offset = new Date().getTimezoneOffset();
  const d = dayjs(date).add(-offset, 'minutes');
  const now = dayjs();

  if (d.isToday()) {
    const hours = now.diff(d, 'hours');
    if (hours >= 1) {
      return Math.floor(hours) + 'h ago';
    }

    const min = now.diff(d, 'minutes');
    return Math.floor(min) + 'min ago';
    /**/
  } else if (d.isYesterday()) {
    const time = d.format('HH:mm');
    return 'Yesterday, ' + time;
  }

  return `${d.format('D MMM YYYY')}, ${d.format('HH:mm')}`;
}

/**
 *
 */
export function getNotificationContent(
  body: string,
  type: ENotification
): ReactElement {
  switch (type) {
    case ENotification.PROMOTION:
      return getPromotedNotificationContent(body);

    case ENotification.ADMIN:
    default:
      return <span>{body}</span>;
  }
}

/**
 *
 */
function getPromotedNotificationContent(body: string): ReactElement {
  try {
    const { articleName, sourceId, sourceName } = JSON.parse(body);
    const path = ROUTES.SERVICE_PROVIDER + '/' + sourceId;

    return (
      <span>
        <span>Service provider </span>
        <span className="NotificationListItem__highlight">{sourceName}</span>
        {/*<Link to={path}>*/}
        {/*  <LinkButton>{sourceName}</LinkButton>*/}
        {/*</Link>*/}
        <span> published the article </span>
        <span className="NotificationListItem__highlight">{articleName}</span>
      </span>
    );
  } catch (e) {
    window.console.error(e);
    return <span>Something went wrong.</span>;
  }
}
