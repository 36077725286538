import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';

import './Header.scss';
import { AccountLink, IconButton, Logo, Search, TrialLink } from 'components';
import { APP_CONTENT, ROUTES } from 'other';
// import NotificationButton from './Notification/NotificationButton';

/**/
const scrollYOffset = 50;
const getPopupContainer = () => document.body;

type Props = {
  isAsideOpen: boolean;
  isLoggedIn: boolean;
  toggleAside: () => void;
};

/**
 *
 */
export function Header({ isAsideOpen, isLoggedIn, toggleAside }: Props) {
  const location = useLocation();
  const [isScrolled, setScrolled] = useState(false);

  const isFixed = !isLoggedIn && location.pathname === ROUTES.HOME;
  const iconName = isAsideOpen ? 'arrow-left' : 'menu';

  const cls = classnames('Header', {
    'Header--fixed': isFixed,
    'Header--scrolled': isScrolled
  });

  /**/
  useEffect(() => {
    const mainContainer = document.getElementById(APP_CONTENT);

    const stickyHeaderOnScroll = () => {
      const active = mainContainer.scrollTop > scrollYOffset;
      setScrolled(active);
    };

    if (isFixed) {
      mainContainer.addEventListener('scroll', stickyHeaderOnScroll);
    } else {
      setScrolled(false);
      mainContainer.removeEventListener('scroll', stickyHeaderOnScroll);
    }

    return () =>
      mainContainer.removeEventListener('scroll', stickyHeaderOnScroll);
  }, [location, isFixed]);

  /**/
  return (
    <header className={cls}>
      <div className="Header__container">
        <IconButton
          className="Header__burger"
          data-testid="Burger"
          icon={iconName}
          onClick={toggleAside}
          size="S"
        />

        <Logo />

        {isLoggedIn && (
          <Search
            className="Header__search"
            getPopupContainer={getPopupContainer}
          />
        )}

        <div className="Header__group">
          <TrialLink isPrimary={true} />
          {/*<NotificationButton />*/}
          <AccountLink isLoggedIn={isLoggedIn} />
        </div>
      </div>
    </header>
  );
}
