import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import './NotificationListItem.scss';
import { IconCustom } from '../../common/IconCustom/IconCustom';
import {
  getNotificationContent,
  getNotificationDate,
  mapTypeToIcon,
  mapTypeToPath
} from './helpers';
import { ENotification, TNotification } from 'types';

/**/
type Props = {
  notification: TNotification;
  onItemClick: (id: number) => void;
};

const ntf = {
  date: '2024-05-22T14:53:22.969Z',
  id: 321,
  read: false,
  type: ENotification.PROMOTION,
  body: JSON.stringify({
    articleId: 17211,
    articleName: 'Skal du fiske i saltvann eller ferskvann?',
    sourceId: 85,
    sourceName: 'FF QA Service Provider'
  })
};

/**
 *
 */
function NotificationListItem({ notification, onItemClick }: Props) {
  const { createdAt, id, read, body, type } = notification;
  const path = mapTypeToPath(body, type);
  const handleClick = () => onItemClick(id);

  const cls = classnames('NotificationListItem', {
    'NotificationListItem--read': read
  });

  return (
    <Link className={cls} to={path} onClick={handleClick}>
      <div className="NotificationListItem__content">
        <div className="NotificationListItem__prefix">
          <IconCustom
            className="NotificationListItem__icon"
            title="Open link"
            type={mapTypeToIcon(type)}
          />
        </div>

        <div>
          <div className="app-body-2 NotificationListItem__text">
            {getNotificationContent(body, type)}
          </div>

          <div className="app-caption NotificationListItem__date">
            {getNotificationDate(createdAt)}
          </div>
        </div>
      </div>
    </Link>
  );
}

/**/
export { NotificationListItem };
